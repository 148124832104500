.react-tel-input .form-control {
  border: 0px;
  border-radius: 0;
  border-bottom: 1px solid #d2d2d2 !important;
  color: #495057;
  font-size: 14px;
  padding: 18.5px 14px 6px 58px;
  width: 100%;
}

.react-tel-input .special-label {
  color: #aaa !important;
  font-weight: 400;
  line-height: 1;
  font-size: 12px;
  z-index: 0;
  left: -3px;
}

.custom-phone-input-controller {
  margin: 0 0 17px 0 !important;
  position: relative !important;
  padding-top: 18px !important;
}

.form-control-radio-input-label > span:nth-child(2) {
  width: 100%;
}

.form-control-radio-input-label > {
  background-color: blue;
}
