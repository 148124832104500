/*!

=========================================================
* Material Kit PRO React - v1.9.0 based on Material Kit PRO v2.0.2 (Bootstrap 4.0.0 Final Edition)
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

@font-face {
    font-family: 'ProjectFancyFont';
    src: url('../../fonts/Montserrat/Montserrat-MediumItalic.ttf') format('opentype');
    /* You can specify font weight, style, etc. here if needed */
  }

  @font-face {
    font-family: 'ProjectFontRegular';
    src: url('../../fonts/Montserrat/Montserrat-Regular.ttf') format('opentype');
  }


  @font-face {
    font-family: 'ProjectFontBold';
    src: url('../../fonts/Montserrat/Montserrat-Bold.ttf') format('opentype');
  }

  @font-face {
    font-family: 'ProjectFontMedium';
    src: url('../../fonts/Montserrat/Montserrat-Medium.ttf') format('opentype');
  }

  @font-face {
    font-family: 'ProjectFontLight';
    src: url('../../fonts/Montserrat/Montserrat-Light.ttf') format('opentype');
  }

// Core Components
@import "core/variables";
@import "core/mixins";
@import "core/fileupload";
@import "core/keyframes";

// Core Plugins
@import "core/misc";

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-image-gallery";
@import "plugins/plugin-react-slick";
@import "plugins/plugin-react-tagsinput";
